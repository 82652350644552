import Q from 'q';
import _ from 'underscore';
import store from '@/lib/store';
// 获取用户信息
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_user_info'
import get_url_prefix from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/get_url_prefix";
import get_token from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_token";
import join from "nllib/src/uri/join";

import aRouter from '@/router'

const redirect_to_user_front_home = function (pParameter) {
    if (!pParameter) pParameter = {};
    const clear_cache_get_user_info = pParameter.clear_cache_get_user_info;

    return Q.when()
        .then(function () {
            return get_user_info({
                clear_cache: clear_cache_get_user_info,
            })
        })
        .then(function (data_get_user_info) {
            const token = get_token();

            // * 用户类型
            // * 账户类型：1-供应商，2-供应商员工，3-采购商，4-采购商员工， 5-平台， 6-游客（EDI）， 7-临时分销渠道
            const userType = data_get_user_info.userVo.userType;

            // 1:机票
            // 2:旅游
            // 1+2:机票+旅游
            const applicateionType = data_get_user_info.applicateionType;

            // 供应商
            if (_.contains([1, 2], userType)) {
                const url_prefix = get_url_prefix({
                    appKey: `frontend-supplier`,
                });
                const url = join(url_prefix, `redirect/?redirectType=000&token=${token}`);
                window.location.href = url;
                return;
            }

            // 分销商，临时分销渠道
            if (_.contains([3, 4, 7], userType)) {

                // 用户信息
                const params = {...data_get_user_info, ...data_get_user_info.userVo};
                store.commit('set_user_info', params);

                //旅游，机票+旅游
                if (_.contains([`2`, `1+2`], applicateionType)) {
                    aRouter.replace({
                        // name: `distributor-tour-front-home`,
                        name: `air-ticket`,
                        params: {
                            route_guard: {
                                enable: false,
                            },
                        },
                    });
                    return;
                }

                //机票
                if (_.contains([`1`], applicateionType)) {
                    aRouter.replace({
                        name: `air-ticket`,
                        params: {
                            route_guard: {
                                enable: false,
                            },
                        },
                    });
                    return;
                }
            }

            // 平台
            if (userType === 5) {
                const msg = 'redirect_to_user_front_home parameter error, code=101';
                console.error(msg);
                console.trace();
                return Q.reject({msg: msg});
            }

            const msg = 'redirect_to_user_front_home parameter error, code=001';
            console.error(msg);
            console.trace();
            return Q.reject({msg: msg});
        })
        .catch(function (ex) {
            console.error(ex);
            console.trace();
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;
};

export default redirect_to_user_front_home;
